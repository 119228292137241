import { Box } from '@mui/material'
import { BreadcrumbBar } from 'componix'
import { UUID } from 'crypto'
import { useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import { useNavbar } from '../contexts/NavbarContext'
import { HierarchyTypes } from '../utils/hierarchy.enum'

const CarrierDetails = () => {
  const { pageStyle } = useNavbar()
  const { carrierGuid } = useParams()
  const { data: ancestorData } = useGetAncestors(carrierGuid as UUID, HierarchyTypes.CARRIER)

  return (
    <Box style={{ ...pageStyle }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Carriers`,
            to: `/carriers`,
          },
          {
            label: `Carrier Group ${ancestorData?.carrierGroup?.identifier}`,
            to: `/carriers/group/${ancestorData?.carrierGroup?.guid}`,
          },
          {
            label: `Carrier ${ancestorData?.carrier?.identifier}`,
            to: `/carriers/${carrierGuid}`,
          },
        ]}
      />
    </Box>
  )
}

export default CarrierDetails
