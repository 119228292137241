import { CarrierGroupCarrierResponse } from '../models/Carrier/CarrierGroupCarrierResponse'
import CarrierGroupInfoResponse from '../models/Carrier/CarrierGroupInfoResponse'
import CarrierResponse from '../models/Carrier/CarrierResponse'
import api from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getCarrierList = async (): Promise<CarrierResponse[]> => {
  const response = await api.get(`${Endpoints.Carriers}`)
  return response.data
}

export const getCarrierGroupInfo = async (carrierGuid: string): Promise<CarrierGroupInfoResponse> => {
  const response = await api.get(`${Endpoints.Carriers}/groups/${carrierGuid}/info`)
  return response.data
}

export const getCarrierGroupCarrierList = async (carrierGuid: string): Promise<CarrierGroupCarrierResponse[]> => {
  const response = await api.get(`${Endpoints.Carriers}/groups/${carrierGuid}/carriers`)
  return response.data
}
